﻿html {
  &.ie11 {
    .sidebar {
      .menu {
        .list {
          .ml-menu {
            li {
              &.active {
                a {
                  &:not(.menu-toggle) {
                    &.toggled {
                      &:before {
                        top: 6px !important;
                        line-height: 20px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .user-info {
        .info-container {
          top: 15px;
        }
      }
    }

    .search-bar {
      input[type="text"] {
        padding: 26px 60px 26px 56px;
      }
    }

    .dropdown-menu {
      ul {
        &.menu {
          li {
            a {
              margin-top: -22px;
            }
          }
        }
      }
    }

    .bs-searchbox {
      .form-control {
        width: 90%;
      }
    }
  }
}
